import React from 'react';
import { ErrorPage } from '~/components/pages/ErrorPage';
import { NextPageWithLayout } from '~/types/layout';
import { useRouter } from 'next/router';
import { SingleActionLayout } from '~/components/layouts/SingleActionLayout';

const Page: NextPageWithLayout = () => {
  const router = useRouter();
  return <ErrorPage onClickBack={() => router.back()} title="404 ページが存在しません" />;
};

Page.Layout = SingleActionLayout;

Page.config = {
  pageTitle: 'ページが存在しません',
};

export default Page;
